.chatHistory {
  padding: 0 10px;
}

.chatHistory .fields {
  flex-wrap: wrap;
}

.datepicker__section {
  margin-top: 14px;
}

.datepicker__period {
  display: flex;
  /* margin-bottom: 20px; */
  align-items: center;
}

.datepicker__period span {
  margin: 0 15px;
}

.chatHistory__messages.ui.card {
  width: 100%;
}

.chatHistory__messages .comments {
  height: calc(100vh - 400px);
  max-width: 100%;
  overflow: auto;
}

.chatHistory__messages .status__label {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 0;
}

.chatHistory__messages .status__label--content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}
