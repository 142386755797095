.dashboard {
  background: #D9DADC;
}

.dashboard__content {
  margin-top: 50px;
  height: calc(100vh - 79px);
  overflow: hidden;
}

.ui.visible.left.sidebar ~ .pusher {
  width: calc(100% - 260px);
}
