.login-form {
  background: url('/assets/babble-bg1.jpg');
  height: 100vh;
}

.login-form .ui.header > .ui.image {
  width: auto;
}

.login-form__message {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}
