.ui.menu.fixed {
  height: 50px;
  padding: 0 18px;
}

.navbar .ui.menu .item:before {
  display: none;
}

.navbar .item .selected {
  background: #e6eafa;
  color: #0a2ecb;
}

.navbar .ui .item {
  padding: 0 6px;
}

.navbar .item a {
  color: #676972;
  font-size: 16px;
  padding: 4px 12px;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .ui.menu.fixed .item.button--hamburger {
    display: none;
  }
}
