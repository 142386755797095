.ui.left.sidebar {
  background-color: #222d32;
}

.ui.left.sidebar.menu .item {
  color: #b8c7ce;
}

.ui.left.sidebar .menu .item {
  font-size: 0.95em;
}

.ui.left.sidebar.menu .item.active {
  color: #ffffff;
  background: rgba(239, 228, 228, 0.05);
}

.ui.left.sidebar.menu .item:hover,
.ui.left.sidebar.menu .item.active:hover {
  color: #ffffff;
  background: rgba(239, 228, 228, 0.05);
}

.ui.vertical.menu .item > .menu {
  margin: 0.5em -1.14286em 0px -0.5em;
}
