.bingoEvents .ui.attached.segment {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}

.bingoEvents .ui.vertical.tabular.menu .active.item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
