.ui.grid > .row.chat__container {
  height: calc(100vh - 80px);
  flex-wrap: nowrap;
  margin: 10px;
  padding: 5px;
}

.ui.card.chat {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.chat > .content {
  overflow: auto;
}

.ui.card.chat__users {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  min-width: 270px;
  margin-right: 1em;
}

.chat .ui.comments {
  max-width: 100%;
  overflow: auto;
}

.chat__scrollButton {
  background-color: #3c8dbc;
  color: #fff;
  padding: 5px 20px;
  text-align: center;
  cursor: pointer;
}

/* .chat .extra.content {
  position: relative;
  display: flex;
  min-height: 70px !important;
  overflow: visible;
} */

.chat .emoji-mart {
  position: absolute;
  bottom: 100%;
  left: 0;
}

.chat .chat__emoji {
  overflow: hidden;
  text-indent: -999em;
  white-space: nowrap;
  background: url(/assets/smiley.svg) no-repeat center 70% #ffcb00;
  cursor: pointer;
  display: block;
  height: 32px;
  width: 32px;
  min-width: 32px;
  flex-basis: 32px;
  border-radius: 50%;
  border: 2px solid #fafafa;
  margin: 0 auto;
  margin-right: 6px;
  padding: 0;
}

.chat .emoji-mart-preview {
  display: none;
}

.chat .chat__form {
  width: 100%;
}

.chat .ui.form textarea {
  resize: none;
}

.chat__bingoEvents h3 {
  display: none;
}

.chat__bingoEvents .bingoEvents .ui.attached.segment {
  max-height: calc(30vh - 40px);
}

.chat__container .content.chat__composer {
  background: #F2F3F3;
  padding: 0;
  overflow: hidden;
}

.chat__form .chat__segment {
  background: #F2F3F3;
}

.chat__form .chat__menu {
  background: #F2F3F3;
}
.chat__form .ui.menu .item:before {
  display: none;
}
.chat__form .ui.menu .item {
  padding-right: 8px;
  padding-left: 8px;
}

