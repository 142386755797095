.ui.divided.selection.list > .item {
  display: flex;
}

.user__content {
  width: 100%;
}

.popup {
  height: auto;
}

.session__id {
  margin-right: 10px;
}

.user__label.ui.label {
  margin-left: 10px;
}
